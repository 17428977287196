<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Fundamentals of Explosives Testing" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This course presents testing protocol and standards for energetic
            (explosive) materials for transportation, storage, facility siting,
            and in-process classification and characterization. The course
            begins with a discussion on the heritage of explosives testing
            before focusing on various test methods and standards. The course
            includes examples and case studies that demonstrate the application
            of material characterization to risk management. This course is
            designed for managers, engineers, safety professionals, and those
            involved in Process Safety Management (PSM) programs. Participants
            will leave the course with a deeper understanding of the elements of
            material characterization testing and the skills to incorporate
            discussed ideas into energetic processes.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Explosives testing heritage</li>
            <li>Sensitivity &amp; reactivity testing</li>
            <li>UN/DOT &amp; ATF hazard classification and test standards</li>
            <li>In-process classification</li>
            <li>Material characterization</li>
            <li>Shipping classification</li>
            <li>Facility siting/ATF license</li>
            <li>Test equipment information</li>
            <li>Explosives Testing Users’ Group (ETUG)</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Fundamentals of Explosive Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course presents testing protocol and standards for energetic (explosive) materials for transportation, storage, facility siting, and in-process classification and characterization."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
